import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Swal from 'sweetalert2'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
import exportValue from "../../apiconfig";
import Sub_header from '../Sub_header';
import Left_panel from "../Left_panel";
import { sub } from "date-fns";
import { useNavigate } from "react-router-dom";

const Customers = () => {
    let navigate = useNavigate()
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search = params.get('search')
    let cType = params.get('type')
   // console.log("search ", cType);
    const [state, setState] = useState({ isLoading: true, user_list: [] });
    const [otherStates, setOtherState] = useState({ dstatus: "", activePage: 1, rowsPerPage: 500, page: 0, total_count: 0, onload: true });
    const [isLoading, setIsLoading] = useState(false);
    const [modalState, setModalState] = useState({ show: false });
    const [newState, setNewState] = useState({ customer_id: "",amount:"",mode:1,cod_wallet_balance:0 });
    const [passwordstate, setpasswordState] = React.useState({customer_id:"" , password:""})
    const [passwordmodal, setpasswordmodal] = React.useState({show:false})

    const [searchfilters, setsearchFilters] = useState({ searchtitle: "" })
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[opencreate , setopencreate] = React.useState({show:false})
    const[registerstate, setregisterstate]= React.useState({full_name:"" , company_name:"" , email:"" , mobile_number:"" , password:""})

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })

    useEffect(() => {
        getUsersList(0, true);
    }, [otherStates.dstatus])

    useEffect(() => {
        getUsersList(0, true);
    }, [])

    const getUsersList = (index = 0, onLoad) => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_list`;
        let sendData = { find: "", status: otherStates.dstatus, user_type: (cType) ? cType :"", limit: otherStates.rowsPerPage, indexValue: index };

        if (search != null && search.length > 0) {
            sendData.searchvalue = search
        } else if (searchfilters.searchtitle != "") {
            sendData.searchvalue = searchfilters.searchtitle
        }
       // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            setIsLoading(false)
            //console.log("response ", res);
           // console.log("otherStates ", otherStates);
            if (index == 0 && onLoad) {
                setOtherState({ ...otherStates, total_count: res.data.count })

            }
            setState({ ...state, user_list: res.data.output, isLoading: false })



        }).catch((e) => {
            setIsLoading(false)

        });
    }

    const handlePageChange = (event, newPage) => {
        setOtherState({ ...otherStates, page: newPage })
        //console.log("newPage ", newPage);
        getUsersList(newPage, true, otherStates.rowsPerPage);
    }
    const handleChangeRowsPerPage = (event) => {
        //console.log("event ", event.target.value);        
        setOtherState({ ...otherStates, rowsPerPage: event.target.value })
        getUsersList(0, true, event.target.value)
    }

    const statusChange = (status) => {
        setOtherState({ ...otherStates, dstatus: status })
    }
    // const addMoneyWallet = (user) => {
    //     console.log(user);
    //     setNewState({ ...newState, customer_id: user.customer_id,amount:0,cod_wallet_balance:user.cod_wallet_balance,mode:1 });
    //     setModalState({show: true})

    // }

    // const inputHandleChange = (e) => {
    //      console.log("e", e);
    //     // console.log("type", e.target.type);
    //     // console.log("name", e.target.name);
    //     // console.log("value", e.target.value);
    //     // console.log("file", e.target.files);
    //     if (e.target.type == "file") {
    //         setNewState({ ...newState, [e.target.name]: e.target.files });
    //     } else {
    //         setNewState({ ...newState, [e.target.name]: e.target.value });
    //     }
    // }

    // const submitAddWalet = () =>{
    //     console.log("newState ",newState);
    //     if(newState.amount != ""){
    //         let flag = true;
    //         if(newState.mode == 2 && newState.amount > newState.cod_wallet_balance ){
    //             flag = false;
    //             Toast.fire({
    //                 background: "#e63900",
    //                 type: 'error',
    //                 title: "Insuficient balance",
    //                 color: "white"
    //             });
    //         }
    //         if(flag){
    //         setIsLoading(true)
    //     let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_wallet_update`;
    //     let sendData= newState;
    //     axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
    //         setIsLoading(false)
    //         setModalState({show: false})
    //         getUsersList(0, true);

    //     }).catch((e) => {
    //         setIsLoading(false)

    //     });
    // }

    //     }else {
    //         Toast.fire({
    //             background: "#e63900",
    //             type: 'error',
    //             title: "Please add amount",
    //             color: "white"
    //         });
    //     }
    // }
// const openpasswordModal=(user)=>{
//   setpasswordState({...passwordstate,customer_id:user.customer_id})
//   setpasswordmodal({show:true})
// }
// const closeModalpassword=()=>{
//     setpasswordState({...passwordstate,customer_id:"", password:""})
//   setpasswordmodal({show:false})
// }

// const passwordchange=(e)=>{
//     setpasswordState({...passwordstate,password:e.target.value})

// }

// const submitPassword=()=>{
    
// if(passwordstate.password!=""){
// let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/customer_temp_password_update`;
// let sendData= {customer_id:passwordstate.customer_id , password:passwordstate.password}
// console.log("send_data === " , sendData);
// axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
//     if(res.data.status==true){
//         setpasswordmodal({show:false})
//         setpasswordState({...passwordstate, customer_id:"" ,password:""})
        
//         Toast.fire({
//             background: "#e63900",
//             type: 'error',
//             title: "Password Created and Valid for Next 30 minutes",
//             color: "white"
//         });
//     }
   
// }).catch((e) => {
//     // setIsLoading(false)
//     Toast.fire({
//         background: "#e63900",
//         type: 'error',
//         title: "Something Went Wrong",
//         color: "white"
//     });

// });


// }else {
//     Toast.fire({
//         background: "#e63900",
//         type: 'error',
//         title: "Please Fill Password",
//         color: "white"
//     });
// }
// }

// const skip_kyc = (user)=>{
   
//         let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/customer_skip_kyc`;
//         let sendData= {customer_id:user.customer_id}
//         console.log("send_data === " , sendData);
//         axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
//             if(res.data.status==true){
//                 Toast.fire({
//                     background: "#e63900",
//                     type: 'error',
//                     title: "KYC Skipped for this Customer !",
//                     color: "white"
//                 });
//             }
//             else{
//                 Toast.fire({
//                     background: "#e63900",
//                     type: 'error',
//                     title: "Something Went Wrong !",
//                     color: "white"
//                 });
//             }
           
//         }).catch((e) => {
//             // setIsLoading(false)
//             Toast.fire({
//                 background: "#e63900",
//                 type: 'error',
//                 title: "Something Went Wrong",
//                 color: "white"
//             });
        
//         });
// }
const filter = () => {
    setIsLoading(true)
    search = null
    params.delete('search')
    if (searchfilters.searchtitle !== "") {
        params.append('search', searchfilters.searchtitle)
    }

    // setState({ ...state, isLoading: true })
    navigate({ search: params.toString() })
    getUsersList(0,true)
}
const stateHandler = (e) => {
    // state[key] = value
    if (e.target.name == "stitle") {

        params.delete('search')
        search = null;
        setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
        //   navigate({ search: params.toString() })

    }
    //    setsearchFilters({ ...searchfilters, searchtitle:searchfilters.searchtitle})
    setOtherState({ ...otherStates, onload: true })
}

const activeInactiveUser =(customer_id , status)=>{
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/update_customer_status_active_inactive`;
            let sendData= {customer_id:customer_id , status:status}
            console.log("send_data === " , sendData);
            axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
                if(res.data.status==true){
                    Toast.fire({
                        background: "#e63900",
                        type: 'error',
                        title: "Status Updated Successfully",
                        color: "white"
                    });
                    getUsersList(0, true);
                }
                else{
                    Toast.fire({
                        background: "#e63900",
                        type: 'error',
                        title: "Something Went Wrong !",
                        color: "white"
                    });
                }
               
            }).catch((e) => {
                // setIsLoading(false)
                Toast.fire({
                    background: "#e63900",
                    type: 'error',
                    title: "Something Went Wrong",
                    color: "white"
                });
            
            });
}

const openModal=()=>{
    setopencreate({show:true})
 }

 const inputHandleChange = (e) => {
    console.log("e", e);

    setregisterstate({...registerstate,[e.target.name]:e.target.value})
  
}

const regitserButton = () =>{
   // console.log("regsterState", regsterState);
   if(registerstate.email != "" && registerstate.password != ""  && registerstate.full_name != "" && registerstate.company_name!="" && registerstate.mobile_number) {
     let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api +`/create_customer_from_admin`;
     let sendData = {email:registerstate.email , password:registerstate.password , full_name:registerstate.full_name , company_name:registerstate.company_name , mobile_number:registerstate.mobile_number};
     // console.log("bb", sendData);
     axios.post(full_api, sendData, {  headers: exportValue.headers  }).then((res) => {
         console.log("res customer ", res);
         if (res.data.status==true) {
             setopencreate({ show: false })
             Toast.fire({
               background:"#206bc4",
               type: 'success',
               title: res.data.message ,
               color:"white"
             });
             getUsersList(0, true);
            
         }else {
           Toast.fire({
             background:"#206bc4",
             type: 'unsuccess',
             title: res.data.message ,
             color:"white"
           });
            
         }
 
     }).catch((e) => {
 
 
     });
   }else {
     Toast.fire({
       background:"#206bc4",
       type: 'unsuccess',
       title: "Please fill all fields",
       color:"white"
     });
   }
 
 }


    return (
//         <div>
//             <Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Add Money</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="row">
//                         <div className="col-md-12 mb-3">
//                             <label for="email">Amount</label>
//                             <input className="form-control" id="first_name" type="number" placeholder="" name="amount" onChange={(e) => inputHandleChange(e)} required />
//                             <span>*Please fill </span>
//                         </div>
//                          <div className="col-md-12 mb-3">
//                             <label for="email">Payment Mode</label><br/>
//                             <input  id="first_name" type="radio" name="mode" onChange={(e) => inputHandleChange(e)} checked={newState.mode == 1} value={1}/> Self<br/>
//                             <input  id="first_name" type="radio" name="mode" onChange={(e) => inputHandleChange(e)} checked={newState.mode == 2} value={2}/> COD
                            

//                         </div>
//                         {(newState.mode == 2)?
//                          <div className="col-md-12 mb-3" >
                      
//                             <label for="email">COD Wallet Balance: </label>
//                             { " "+newState.cod_wallet_balance}
                         
//                         </div>  :""
//                         }
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setModalState({ show: false })}>
//                         Cancel
//                     </Button>
//                     <Button variant="primary"
//                         onClick={() =>
//                             submitAddWalet()
//                         }>
//                         Pay
//                     </Button>
//                 </Modal.Footer>
//             </Modal>

//             <Modal show={passwordmodal.show} onHide={() => closeModalpassword()}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Create Temp Password</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="row">
//                         <div className="col-md-12 mb-3">
//                             <label for="email">Password</label>
//                             <input className="form-control" id="first_name" type="text" placeholder="" name="password" value={passwordstate.password} onChange={(e) => passwordchange(e)} required />
//                             {/* <span>*Please fill </span> */}
//                         </div>
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => closeModalpassword()}>
//                         Cancel
//                     </Button>
//                     <Button variant="primary"
//                         onClick={() =>
//                             submitPassword()
//                         }>
//                         Create Password
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//             <Sub_header />
//             <div style={{ marginLeft: "15px", marginRight: "15px" }}>
//                 <section>

//                     <div class="row py-4">
//                         <div class="col-12 col-md">
//                             <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
//                                 <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
//                                     <li class="breadcrumb-item">
//                                         <a href="#">
//                                             <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
//                                         </a>
//                                     </li>
//                                     <li class="breadcrumb-item active" aria-current="page">Customers</li>
//                                 </ol>
//                             </nav>
//                             <h2 class="h4">All Customers</h2>
//                         </div>
//                         <div class="col-12 col-md-5">

//                         </div>
//                         <div class="col-12 col-md text-end">
//                             <div class="">
//                                 <div class="input-group ">
//                                     <span class="input-group-text">
//                                         <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
//                                             <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
//                                         </svg>
//                                     </span>
//                                     <input type="text" class="form-control" placeholder="Search Customers" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>


//                     <section>
//                         <ul class="nav nav-tabs justify-content-end">
//                             <li class="nav-item">
//                                 <a class={"nav-link" + (otherStates.dstatus == "" ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange("")}>All</a>
//                             </li>
//                             <li class="nav-item">
//                                 <a class={"nav-link" + (otherStates.dstatus == 1 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(1)}>Active</a>
//                             </li>
//                             <li class="nav-item">
//                                 <a class={"nav-link" + (otherStates.dstatus == 2 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(2)}>Inactive</a>
//                             </li>
//                             <li class="nav-item">
//                                 <a class={"nav-link" + (otherStates.dstatus == 4 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(4)}>Deleted</a>
//                             </li>
//                             <li class="nav-item">
//                                 <a class={"nav-link" + (otherStates.dstatus == 3 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(3)}>Suspeded</a>
//                             </li>
//                         </ul>
//                     </section>
//                     {(isLoading) ?
//                         <div style={{ backgroundColor: "#808080", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.5" }}>
//                             <div style={{ left: "47%", top: "45%", position: "absolute" }}>
//                                 <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
//                             </div>
//                         </div> :
//                         <div class="card card-body border-0 shadow table-wrapper table-responsive">

//                             <table class="table table-hover">
//                                 <thead>
//                                     <tr>
//                                         <th class="border-gray-200">#</th>
//                                         <th class="border-gray-200">Account#</th>
//                                         <th class="border-gray-200">Name</th>
//                                         <th class="border-gray-200">Phone#</th>
//                                         <th class="border-gray-200">Email</th>
//                                         <th class="border-gray-200">Wallet</th>
//                                         <th class="border-gray-200">COD Wallet</th>
//                                         <th class="border-gray-200">Available Credit balance</th>
//                                         <th class="border-gray-200">Status</th>
//                                         <th class="border-gray-200">Action</th>
//                                         <th class="border-gray-200">Add money to wallet</th>
//                                         <th class="border-gray-200">Add Pricing</th>
                                     

//                                     </tr>
//                                 </thead>

//                                 <tbody>
//                                     {state.user_list.map((user, index) =>
//                                         <tr>
//                                             <td>
//                                                 {index + 1}
//                                             </td>
//                                             <td>
//                                                 <span class="fw-normal"><a href={"/customers_info/"+user.customer_id}>{user.customer_id}</a></span>
//                                             </td>
//                                             <td>
//                                                 <div class="d-flex">
//                                                     <div class="">
                                                     
//                                                     </div>
//                                                     <div class="p-1">
//                                                         <a href={"/customers_info/"+user.customer_id} class="fw-bold text-info fw-700">
//                                                             {user.full_name}
//                                                         </a><br />
//                                                         <small class="text-muted">New Delhi, India, </small>
//                                                     </div>
//                                                 </div>

//                                             </td>
//                                             <td>{user.moble}</td>
//                                             <td>{user.email}</td>
//                                             <td>{(user.wallet_balance) ? user.wallet_balance.toFixed(2):0} </td>
//                                             <td>{(user.cod_wallet_balance) ? user.cod_wallet_balance.toFixed(2):0} </td>
//                                             <td>
//   {user.wallet_balance < 0 && user.credit_limit
//     ? Number(user.wallet_balance) + Number(user.credit_limit)
//     : user.credit_limit || 0}
// </td>

//                                             <td>
//                                                 {(user.status == 1) ?
//                                                     <span class="badge rounded-pill bg-success">Active</span> :
//                                                     (user.status == 2) ?
//                                                         <span class="badge rounded-pill bg-success">Inactive</span> :
//                                                         (user.status == 3) ?
//                                                             <span class="badge rounded-pill bg-success">Suspended</span> :
//                                                             (user.status == 4) ?
//                                                                 <span class="badge rounded-pill bg-success">Deleted</span> : ""
//                                                 }
//                                             </td>
//                                             <td>
//                                                 <div class="btn-group">
//                                                     <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                                         <span class="icon icon-sm">
//                                                             <span class="fa fa-ellipsis-h icon-dark"></span>
//                                                         </span>
//                                                         <span class="visually-hidden">Toggle Dropdown</span>
//                                                     </button>
//                                                     <div class="dropdown-menu py-0">
//                                                         <a class="dropdown-item rounded-top" href="#"><span class="fa fa-eye me-2"></span>View/Update</a>
//                                                         <a class="dropdown-item rounded-top" href="#"><span class="fa fa-eye-slash me-2"></span>Inactive User</a>
//                                                         <a class="dropdown-item text-danger rounded-top" href="#"><span class="fa fa-ban me-2"></span>Suspend User</a>
//                                                         <a class="dropdown-item text-danger rounded-bottom" href="#"><span class="fa  fa-trash me-2"></span>Remove</a>
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             <td><button class="btn btn-primary" onClick={()=>addMoneyWallet(user)}><i class="fa fa-plus"></i></button></td>
//                                             <td>
//                                                 <a class="btn btn-primary" href={`products/${user.customer_id}`}>Set Pricing</a>
//                                             </td>
//                                             <td>
//                                             <a class="btn btn-primary" href={`master_new_customer/${user.customer_id}`}>Set Carrier Detail</a>
//                                             </td>
//                                             <td>
//                                             <a class="btn btn-primary" onClick={(e)=>openpasswordModal(user)}>Create Temp Password</a>
//                                             </td>
//                                             <td>
//                                             <a class="btn btn-primary" onClick={(e)=>skip_kyc(user)}>Skip KYC</a>
//                                             </td>

//                                         </tr>
//                                     )}
//                                 </tbody>

//                             </table>

//                             <TablePagination
//                                 component="div"
//                                 rowsPerPageOptions={[5, 10]}
//                                 count={otherStates.total_count}
//                                 page={otherStates.page}
//                                 onPageChange={handlePageChange}
//                                 rowsPerPage={otherStates.rowsPerPage}
//                                 onRowsPerPageChange={handleChangeRowsPerPage}

//                             />
                         
//                         </div>

//                     }
//                 </section>
//             </div>
//         </div>
<div>
{/* <?php
            include('header.php');
        ?> */}

        {/* <?php
            include('left_nav.php');
        ?> */}
       <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <section class="home-section">
            {/* <?php
            include('sub_header.php');
            ?>  */}
            <Sub_header/>
            <Modal show={opencreate.show} onHide={() => setopencreate({ show: false })}>
                 <Modal.Header closeButton>
                     <Modal.Title>Create Customer</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>
                     <div className="row">
                         <div className="col-md-12 mb-3">
                             <label for="email">Full Name</label>
                             <input className="form-control" id="first_name" type="text" placeholder="" name="full_name" onChange={(e)=>inputHandleChange(e)} required />
                         </div>
                         <div className="col-md-12 mb-3">
                             <label for="email">Company Name</label>
                             <input className="form-control" id="first_name" type="text" placeholder="" name="company_name" onChange={(e)=>inputHandleChange(e)}  required />
                         </div> <div className="col-md-12 mb-3">
                             <label for="email">Email</label>
                             <input className="form-control" id="first_name" type="text" placeholder="" name="email" onChange={(e)=>inputHandleChange(e)} required />
                         </div> <div className="col-md-12 mb-3">
                             <label for="email">Mobile Number</label>
                             <input className="form-control" id="first_name" type="number" placeholder="" name="mobile_number" onChange={(e)=>inputHandleChange(e)}  required />
                         </div> <div className="col-md-12 mb-3">
                             <label for="email">Password</label>
                             <input className="form-control" id="first_name" type="password" placeholder="" name="password" onChange={(e)=>inputHandleChange(e)}  required />
                         </div>
                         
                      
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setopencreate({ show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            regitserButton()
                        }
                        >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        <div class="row py-4">
        <div class="col-12 col-md">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                    <li class="breadcrumb-item">
                        <a href="#">
                            <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Customers</li>
                </ol>
            </nav>
            <h2 class="h4">All Customers</h2>
        </div>
        <div class="col-12 col-md-5">
            
        </div>
        <div class="col-12 col-md text-end">
            <div class="">
                <div class="input-group ">
                    <span class="input-group-text">
                    <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" onClick={(e) => filter(e)}>
                                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                        </svg>
                    </span>
                    <input type="text" class="form-control" placeholder="Search Customers" name="stitle" value={searchfilters.searchtitle} onChange={(e) => stateHandler(e)} />
                </div>
            </div>
        </div>
        </div>
    
            
            <section>
            <div><a className="btn btn-primary" onClick={(e)=>openModal(e)}>Create Customer</a></div>
                <ul class="nav nav-tabs justify-content-end">
                <li class="nav-item">
                                <a class={"nav-link" + (otherStates.dstatus == "" ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange("")}>All</a>
                             </li>
                             <li class="nav-item">
                                 <a class={"nav-link" + (otherStates.dstatus == 1 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(1)}>Active</a>
                             </li>
                             <li class="nav-item">
                                 <a class={"nav-link" + (otherStates.dstatus == 2 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(2)}>Inactive</a>
                             </li>
                             <li class="nav-item">
                                 <a class={"nav-link" + (otherStates.dstatus == 4 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(4)}>Deleted</a>
                             </li>
                             {/* <li class="nav-item">
                                 <a class={"nav-link" + (otherStates.dstatus == 3 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(3)}>Suspended</a>
                             </li> */}
                </ul>
            </section>
            {(isLoading) ?
                        <div style={{ backgroundColor: "#808080", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.5" }}>
                            <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                                <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                            </div>
                        </div> :
            <div class="card card-body border-0 shadow table-wrapper table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th class="border-gray-200">#</th>
                            <th class="border-gray-200">Account#</th>	
                            <th class="border-gray-200">Company Name</th>
                            <th class="border-gray-200">Phone#</th>
                            <th class="border-gray-200">Email</th>	
                            <th class="border-gray-200">Status</th>
                            <th class="border-gray-200">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {state.user_list.map((user, index) =>
                        <tr>
                            <td>
                                {index+1}
                            </td>
                            <td>
                                <span class="fw-normal"><a href={"/customers_info/"+user.customer_id}>{user.customer_id}</a></span>
                            </td>                        
                            <td>
                            <div class="d-flex">
                                <div class="">
                                <img src={`https://dummyimage.com/50/1976d2/ffffff&text=${user.full_name.slice(0, 2)}`} class="img-fluid rounded-circle" />
                                </div>
                                <div class="p-1">
                                <a href={"/customers_info/"+user.customer_id} class="fw-bold text-info fw-700">
                                    {user.company_name?user.company_name:user.full_name}
                                </a><br/>
                                    <small class="text-muted">{user.city?user.city:""}  {user.state?user.state:""} {user.country?user.country:""} </small>
                                </div>
                            </div>
                                
                            </td>
                            <td>{user.mobile}</td>
                            <td>{user.email}</td>
                            <td>
                                               {(user.status == 1) ?
                                                    <span class="badge rounded-pill bg-success">Active</span> :
                                                    (user.status == 2) ?
                                                        <span class="badge rounded-pill bg-danger">Inactive</span> :
                                                        (user.status == 3) ?
                                                            <span class="badge rounded-pill bg-success">Suspended</span> :
                                                            (user.status == 0) ?
                                                                <span class="badge rounded-pill bg-danger">Deleted</span> : ""
                                                }
                                            </td>
                            <td>
                                <div class="btn-group">
                                    <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="icon icon-sm">
                                            <span class="fa fa-ellipsis-h icon-dark"></span>
                                        </span>
                                        <span class="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <div class="dropdown-menu py-0">
                                    {/* <a class="dropdown-item rounded-top" href="#"><span class="fa fa-eye me-2"></span>View/Update</a> */}
                                    {user.status==1?<a class="dropdown-item rounded-top" onClick={(e)=>activeInactiveUser(user.customer_id,2)}><span class="fa fa-eye-slash me-2"></span>Inactive User</a>:<a class="dropdown-item rounded-top" onClick={(e)=>activeInactiveUser(user.customer_id,1)}><span class="fa fa-eye-slash me-2"></span>Active User</a>}
                                                         
                                                         {/* <a class="dropdown-item text-danger rounded-top" href="#"><span class="fa fa-ban me-2"></span>Suspend User</a> */}
                                                         <a class="dropdown-item text-danger rounded-bottom" onClick={(e)=>activeInactiveUser(user.customer_id,0)}><span class="fa  fa-trash me-2"></span>Remove</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                                                    
                    </tbody>
                </table>
                <TablePagination
                                component="div"
                                rowsPerPageOptions={[500,800,1000]}
                                count={otherStates.total_count}
                                page={otherStates.page}
                                onPageChange={handlePageChange}
                                rowsPerPage={otherStates.rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}

                            />
                         
            </div> 
}     

    </section>


{/* <?php
include('footer.php');
?>   */}
</div>
    )
}

export default Customers
