import React, { useEffect, useRef } from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import '../../components/loader.css';
import TablePagination from '@mui/material/TablePagination';
import { Container, Row, Col, Form, Button, ProgressBar, ModalFooter, NavItem } from "react-bootstrap"
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'

const Wallet_payment = () => {
    let navigate = useNavigate();
    const inputRef = useRef(null);
    const[dimensionState1,setdimensionState1] = React.useState([])
    const [focusedIndex, setfocusedIndex] = React.useState(-1);
    const [rstate, setRState] = React.useState({
        suggestions: [],
        text: "",
        customer_id:""
      });
      console.log("rstate= " , rstate)
    const [state, setState] = React.useState({
        transaction_list: [],
        dataCount: 0,
        isLoading: true,
        dialogOpen: false,
        searchValue: "",
       

    })
    const[customeridstate , setcustomeridstate]= React.useState({customer_id:""})

    const search = window.location.search;
    // console.log("search -   ",search);
    const params = new URLSearchParams(search);
    let transaction= params.get('transaction')
 
   
  const [shipmentstate, setshipStatus] = React.useState({ status: null });
const[modals,setModals] = React.useState({show: false,transaction_id:"",shipment_id:"",created:"",date:"",merchant_id:"",full_name:"",payer_email:"",payer_id:"",paid_amount:"",currency_code:"",t_uid:"",payment_method:"",status:"",service_charge:"",online_id:"",remaining_amount:"",transporter_advance:"",admin_advance:"",invoice_id:""})
const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:500,page:0,total_count:0,onload:true});


const [searchfilters, setsearchFilters] = React.useState({ searchtitle: "",filter:[{name:"credit",value:1,status:true},{name:"debit",value:2,status:true}],filterStatus:[]})
const [methodsearchfilters, setmethodsearchFilters] = React.useState({ methodfilter:[{name:"refund",value:"Refund",status:true},{name:"wallet",value:"Wallet",status:true},{name:"razorpay",value:"RazorPay",status:true},{name:"admin",value:"Admin",status:true}],methodfilterStatus:[]})
console.log("methodsearchfilters == " , methodsearchfilters)
const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};

useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    console.log("urlSearchParams == " , urlSearchParams)
    // Get and set the customer_id from the URL
    const urlCustomerID = urlSearchParams.get('customer_id');
    const urlStatus = urlSearchParams.get('status');
    console.log("urlStatus == " , urlStatus)
    const decodedStatus = decodeURIComponent(urlStatus);
    const statusArray = decodedStatus.split(',');
    const statusNumbers = statusArray.map(Number);
    console.log("statusNumbers == " , statusNumbers)
    const urlmethod = urlSearchParams.get('method');
    console.log("urlmethod == " , urlmethod)
    const decodedmethod = decodeURIComponent(urlmethod);
    const methodArray = decodedmethod.split(',');
    const methodNumbers = methodArray.map(Number);
    console.log("methodNumbers == " , methodNumbers)
    // const order_number = urlSearchParams.get('number');
   
    if (urlCustomerID
          && !urlStatus
            && !urlmethod
         ) {
      
          console.log('Customer ID:', urlCustomerID);
          let full_api1 = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_id_profile`;
          let sendData1 = {customer_id:urlCustomerID};
        
          axios.post(full_api1, sendData1, { headers: exportValue.headers }).then(res => {
            // setdimensionState(res.data.output);
            setRState({...rstate , text:res.data.output[0].full_name })
            setcustomeridstate({...customeridstate , customer_id:res.data.output[0].customer_id})
            console.log("rnmmmmm", res);
          }).catch((e) => {
            // toast.configure()
             //toast.error("Some thing went wrong")
            console.log("----error:   ", e);
          })
        //   console.log("onLoad--->",onLoad)
        let onLoad = true
        let index = 0 
        //  setIsLoading(true)
         let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/wallet_transaction_list`;
         let sendData = {
             find:"",
             current_status: "",
             indexValue: index,
             limit:otherStates.rowsPerPage
         };
        
         if(transaction!=null && transaction.length>0){
             sendData.find= transaction
         }
         if(customeridstate.customer_id!=""){
            sendData.customer_id = customeridstate.customer_id
         }
          console.log("send",sendData)
         axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
             
 
             if(index==0  && onLoad) {
                 setOtherState({...otherStates,total_count:res.data.dataCount})         
             }
            
          setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
           
           console.log( "response",res);
            })
       
      }
      else if(!urlCustomerID &&!urlmethod &&urlStatus && statusNumbers.length>0){
        let onLoad = true
        let index = 0 
        //  setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/wallet_transaction_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:"",
          
        };
        console.log("sendData",sendData)
      
            sendData.current_status= statusNumbers
        
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            // setIsLoading(false)
            if(index==0  && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
            }
           
         setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
          
          console.log( "response",res);
       
        }).catch((e) => {
            // setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(urlmethod && methodsearchfilters.methodfilterStatus.length>0 && !urlCustomerID && !urlStatus ){
        // setsearchshipment({shipment_id:order_number})
        let onLoad = true
        let index = 0 
        //  setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/wallet_transaction_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            
           
        };
        console.log("sendData",sendData)
            sendData.payment_method = methodsearchfilters.methodfilterStatus
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            // setIsLoading(false)
            if(index==0  && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
            }
           
         setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
          
          console.log( "response",res);
        }).catch((e) => {
            // setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(urlCustomerID &&urlStatus && statusNumbers.length>0 && !urlmethod){
        console.log('Customer ID:', urlCustomerID);
        let full_api1 = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_id_profile`;
        let sendData1 = {customer_id:urlCustomerID};
      
        axios.post(full_api1, sendData1, { headers: exportValue.headers }).then(res => {
          // setdimensionState(res.data.output);
          setRState({...rstate , text:res.data.output[0].full_name })
          setcustomeridstate({...customeridstate , customer_id:res.data.output[0].customer_id})
          console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
        let onLoad = true
        let index = 0 
        //  setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/wallet_transaction_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:urlCustomerID,
          
        };
        console.log("sendData",sendData)
      
        if(searchfilters.searchtitle != ""){
            sendData.find= searchfilters.searchtitle
        }
        // if(searchfilters.filterStatus.length > 0) {
            sendData.current_status= statusNumbers
        // }
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            // setIsLoading(false)
            if(index==0  && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
            }
           
         setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            // setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(urlCustomerID && !urlStatus && urlmethod && methodsearchfilters.methodfilterStatus.length>0){
        console.log('Customer ID:', urlCustomerID);
        let full_api1 = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_id_profile`;
        let sendData1 = {customer_id:urlCustomerID};
      
        axios.post(full_api1, sendData1, { headers: exportValue.headers }).then(res => {
          // setdimensionState(res.data.output);
          setRState({...rstate , text:res.data.output[0].full_name })
          setcustomeridstate({...customeridstate , customer_id:res.data.output[0].customer_id})
          console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
        let onLoad = true
        let index = 0 
        //  setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/wallet_transaction_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:urlCustomerID,
           

        };
        console.log("sendData",sendData)
        sendData.payment_method = methodsearchfilters.methodfilterStatus
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            // setIsLoading(false)
            if(index==0  && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
            }
           
         setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            // setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(!urlCustomerID && urlStatus && statusNumbers.length>0 && urlmethod && methodsearchfilters.methodfilterStatus.length>0){
        let onLoad = true
        let index = 0 
        //  setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/wallet_transaction_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:"",
           
        };
        console.log("sendData",sendData)
         sendData.payment_method= methodsearchfilters.methodfilterStatus
         sendData.current_status = statusNumbers
        
         console.log("send",sendData)
         axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            // setIsLoading(false)
            if(index==0  && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
            }
           
         setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            // setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else if(urlCustomerID && urlStatus && statusNumbers.length>0 &&urlmethod && methodsearchfilters.methodfilterStatus.length>0){
        console.log('Customer ID:', urlCustomerID);
        let full_api1 = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_id_profile`;
        let sendData1 = {customer_id:urlCustomerID};
      
        axios.post(full_api1, sendData1, { headers: exportValue.headers }).then(res => {
          // setdimensionState(res.data.output);
          setRState({...rstate , text:res.data.output[0].full_name })
          setcustomeridstate({...customeridstate , customer_id:res.data.output[0].customer_id})
          console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
        let onLoad = true
        let index = 0 
        //  setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/wallet_transaction_list`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
            customer_id:urlCustomerID,
           

        };
        console.log("sendData",sendData)
         sendData.payment_method= methodsearchfilters.methodfilterStatus
         sendData.current_status = statusNumbers
        
         console.log("send",sendData)
         axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            // setIsLoading(false)
            if(index==0  && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
            }
           
         setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
        }).catch((e) => {
            // setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })
      }
      else{
    
     axios_get_api()    }
    
   }, [otherStates.rowsPerPage,searchfilters.filterStatus,customeridstate.customer_id , methodsearchfilters.methodfilterStatus])

const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  React.useEffect(() => {
    dimension_detail();
    axios_get_api(shipmentstate.status)
    customerProfile()
   
  }, [])
  const customerProfile =()=>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_profile`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState1(res.data.output);
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
 }

   const customerHandle =(e)=>{
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      dimensionState1.map((sub)=>{
        if(sub.email.match(regex)!=null){
            suggestions.push(sub)
        }
    })
    //   suggestions = dimensionState.sort().filter((full_name) => regex.test(full_name));
      console.log("suggestions ==" , suggestions)

    }
    setRState(() => ({
      suggestions,
      text: value,
    }));
   }
   
 
    
    function selectedText(value) {
        setRState(() => ({
            text: value.full_name,
            suggestions: [],
          }));
          setcustomeridstate({...customeridstate, customer_id:value.customer_id})
          const url = new URL(window.location);
  url.searchParams.set('customer_id', value.customer_id); // You can choose the appropriate query parameter name
  window.history.pushState({}, '', url.toString());
       
    }

    const axios_get_api = (status = null,index=0,onLoad) => {
         console.log("dstatus--->",index)
      
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/wallet_transaction_list`;
        let sendData = {
            find:"",
            current_status: status,
            indexValue: index,
            limit:otherStates.rowsPerPage
        };
       
        if(transaction!=null && transaction.length>0){
            sendData.find= transaction
        }
        const urlSearchParams = new URLSearchParams(window.location.search);
        console.log("urlSearchParams == " , urlSearchParams)
        // Get and set the customer_id from the URL
        const urlCustomerID = urlSearchParams.get('customer_id');
        if(urlCustomerID){
            sendData.customer_id = urlCustomerID
        }
        const urlStatus = urlSearchParams.get('status');
        console.log("urlStatus == " , urlStatus)
        const decodedStatus = decodeURIComponent(urlStatus);
        const statusArray = decodedStatus.split(',');
        const statusNumbers = statusArray.map(Number);
        console.log("statusNumbers == " , statusNumbers)
        if(urlStatus){
            sendData.current_status = statusNumbers
        }
        const urlmethod = urlSearchParams.get('method');
        console.log("urlmethod == " , urlmethod)
        const decodedmethod = decodeURIComponent(urlmethod);
        const methodArray = decodedmethod.split(',');
        const methodNumbers = methodArray.map(Number);
        if(urlmethod){
            sendData.payment_method = methodsearchfilters.methodfilterStatus 
        }
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            

            if(index==0  && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
            }
           
         setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
          
          console.log( "response",res);
       


        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })

    }
   
    React.useEffect(()=>{
       axios_get_api(null,0,true)
    },[])

//     const searchUser = ( status,index=0) => {
//         let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/transaction_find_detail`;
            
//         let transporter_data= { indexValue:index, limit:otherStates.rowsPerPage, dstatus:1,transaction_id:"", status:status,};
//         // console.log("pv",state.title)
//         if ( searchfilters.searchtitle!="") {
//            transporter_data.transaction_id=  searchfilters.searchtitle;
//          }
//          console.log("transporter",transporter_data)
       
//     axios.post(full_api,transporter_data, { headers: exportValue.headers }).then(res => {        
// //    setState({ ...state,searchValue:res.data.title})
//    console.log( "response123",res);
//    if(index==0 && otherStates.onload) {
//     setOtherState({...otherStates,total_count:res.data.dataCount,onload:false})         
// }  
//    setState({ ...state, transaction_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
   
   

//    }).catch((e) => {
//     Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: 'Something went wrong!',
        
//       })
//        console.log("----error:   ", e);
//    })          
//   }

// const stateHandler = (e) => {
//     // state[key] = value
//     if (e.target.name == "stransaction") {
      
//             params.delete('transaction')
//             transaction= null;
//           setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
//             //   navigate({ search: params.toString() })

//         }
// //    setsearchFilters({ ...searchfilters, searchtitle:searchfilters.searchtitle})
//     setOtherState({...otherStates,onload:true})
// }
const filter=(e,values)=>{
    const name = e.target.name;
    const value = values;
    const isChecked = e.target.checked;
  
    // Create a new array by spreading the existing filterStatus array
    const updatedFilterStatus = [...searchfilters.filterStatus];
  
    if (isChecked) {
      updatedFilterStatus.push(value);
    } else {
      // Remove the value from the array if unchecked
      const index = updatedFilterStatus.indexOf(value);
      if (index !== -1) {
        updatedFilterStatus.splice(index, 1);
      }
    }
  
    // Update the searchfilters state with the updated filterStatus array
    setsearchFilters((prevFilters) => ({
      ...prevFilters,
      filterStatus: updatedFilterStatus,
    }));
    
    const selectedStatus = updatedFilterStatus;
    const statusQueryString = `status=${selectedStatus.join(',')}`;
    
    // Get the existing query parameters
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    
    // Set the new status parameter
    searchParams.set('status', selectedStatus.join(','));
    
    // Update the URL with the modified query parameters
    url.search = searchParams.toString();
    window.history.pushState({}, '', url.toString());
  }
  const method_filter=(e,values)=>{
    const name = e.target.name;
    const value = values;
    const isChecked = e.target.checked;
  
    // Create a new array by spreading the existing filterStatus array
    const updatedFilterStatus = [...methodsearchfilters.methodfilterStatus];
  
    if (isChecked) {
      updatedFilterStatus.push(value);
    } else {
      // Remove the value from the array if unchecked
      const index = updatedFilterStatus.indexOf(value);
      if (index !== -1) {
        updatedFilterStatus.splice(index, 1);
      }
    }
  
    // Update the searchfilters state with the updated filterStatus array
    setmethodsearchFilters((prevFilters) => ({
      ...prevFilters,
      methodfilterStatus: updatedFilterStatus,
    }));
    
    const selectedStatus = updatedFilterStatus;
    const statusQueryString = `method=${selectedStatus.join(',')}`;
    
    // Get the existing query parameters
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    
    // Set the new status parameter
    searchParams.set('method', selectedStatus.join(','));
    
    // Update the URL with the modified query parameters
    url.search = searchParams.toString();
    window.history.pushState({}, '', url.toString());
  }
React.useEffect(()=>{
    if (transaction) {
        setsearchFilters({...searchfilters,searchtitle:transaction})
    }
// searchUser(null,0)
// axios_get_api(null,0,true);


},[])

    
    const shipmentFilter = (status) => {
        // console.log("action ",dstatus);
        setshipStatus({ ...shipmentstate, status: status })
      
        axios_get_api(status,0,true)
        
      }
   
   
      const handlePageChange = (event,newPage) =>{
        // console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        axios_get_api(0,newPage);
        
       // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
     console.log("event ", event.target.value);
     console.log("newPage ", newPage);
       setOtherState({...otherStates, rowsPerPage:event.target.value, page:0})
       
       
    }

    const modalValues = (item) =>{
        console.log(item);
        setModals(item)
    }
    const handleKeyDown = (e) => {
        console.log("e == " , e.key)
        if (e.key == 'ArrowDown') {
          e.preventDefault();
          if (rstate.suggestions.length > 0) {
            console.log("suggestions length === " , rstate.suggestions.length)
            let selectedItem = 0
             selectedItem =  (focusedIndex + 1) % rstate.suggestions.length
            console.log("selectedItem == " , selectedItem)
            setfocusedIndex(selectedItem)
          }
        }
         else if (e.key === 'ArrowUp') {
          e.preventDefault();
          if (rstate.suggestions.length > 0) {
            let selectedItemIndex = 0
             selectedItemIndex = (focusedIndex - 1 + rstate.suggestions.length) % rstate.suggestions.length;
           setfocusedIndex(selectedItemIndex)
          }
        } else if (e.key === 'Enter') {
          if (focusedIndex !== -1) {
            // Handle Enter key press, e.g., select the item, and clear the input field
            const selectedItem = rstate.suggestions[focusedIndex];
            console.log("selectedItem ==" , selectedItem)
            selectedText(selectedItem);
           
          }
        }
      };
  return (
    <div>
  <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>    

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            {/* <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li> */}
                            <li class="breadcrumb-item active" aria-current="page">Payments</li>
                        </ol>
                    </nav>
                    <h2 class="h4">Wallet Transactions List</h2>
                </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-2">
                <label>Customer Name</label>
                          <section className=''> <input className='form-control' name="customer_name" placeholder='Enter Customer Name' onChange={(e)=>customerHandle(e)} value={rstate.text} autoComplete='off' onKeyDown={(e) => handleKeyDown(e)}  ref={inputRef}/>
            <ul>
            {rstate.suggestions.map((item, index) => (
            <li 
            className="form-control"
            value={rstate.text}
           
            key={index}
          onClick={() => selectedText(item)}
         
          style={{
            listStyle: 'none',
            backgroundColor: index === focusedIndex ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.05)",
            border: "1px solid #CCC",
            boxShadow: "inset 1px 1px 0 rgba(255, 255, 255, 0.5)",
            cursor: "pointer",
            borderRadius: "3px",
            padding: "10px 15px 10px 25px",
           
          }}>
                {item.full_name} , {item.email}
            </li>
            ))}
          </ul></section>
                      
                    </div>
        <div class="col-12 col-md-2">
        <label>Payment Type</label>
        <div class=" btn-toolbar mb-2 mb-md-0" >
               
               <div class="btn-group">
              
                   <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-filter" aria-hidden="true"></i> &nbsp;
                       Filter <i class="fa fa-angle-down" aria-hidden="true"></i>
                   </button>
                   <div class="dropdown-menu">
                       <section class="p-2">
                       <h6>Filter</h6>
                       <hr/>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="credit" value="something"  onChange={(e)=>filter(e,1)}/>
                               <label class="form-check-label">Credit</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="debit" value="something" onChange={(e)=>filter(e,2)}/>
                               <label class="form-check-label">Debit</label>
                           </div>
                           {/* <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="refund" value="something"  onChange={(e)=>filter(e,3)}/>
                               <label class="form-check-label">Refund</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="wallet" value="something"  onChange={(e)=>filter(e,4)}/>
                               <label class="form-check-label">Wallet </label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="razorpay" value="something"  onChange={(e)=>filter(e,5)}/>
                               <label class="form-check-label">RazorPay</label>
                           </div> */}
                          
                       </section>
                     
                   </div>
                 </div>

           </div>
        </div>
        <div class="col-12 col-md-2">
        <label>Payment Method</label>
        <div class=" btn-toolbar mb-2 mb-md-0" >
               
               <div class="btn-group">
              
                   <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-filter" aria-hidden="true"></i> &nbsp;
                       Filter <i class="fa fa-angle-down" aria-hidden="true"></i>
                   </button>
                   <div class="dropdown-menu">
                       <section class="p-2">
                       <h6>Filter</h6>
                       <hr/>
                          
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="refund" value="something"  onChange={(e)=>method_filter(e,'Refund')}/>
                               <label class="form-check-label">Refund</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="wallet" value="something"  onChange={(e)=>method_filter(e,'Wallet')}/>
                               <label class="form-check-label">Wallet </label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="razorpay" value="something"  onChange={(e)=>method_filter(e,'RazorPay')}/>
                               <label class="form-check-label">RazorPay</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="admin" value="something"  onChange={(e)=>method_filter(e,'Admin')}/>
                               <label class="form-check-label">Admin</label>
                           </div>
                          
                       </section>
                     
                   </div>
                 </div>

           </div>
        </div>
        {/* <div className='col-12 col-md-2'>
      
    
            <label>Start Date</label>
            <input className='form-control' type='date'
            //  onChange={(e)=>handleselectstartdate(e)}
             ></input> 
                      
        </div>
        <div className='col-12 col-md-2'>
      
    
      <label>End Date</label>
      <input className='form-control' type='date'
    //    onChange={(e)=>handleselectstartdate(e)}
       ></input> 
                
  </div> */}

      
    </div>
            <section>
                <ul class="nav nav-tabs justify-content-end">
                    <li class="nav-item">
                      <a class={(shipmentstate.status == null)?"nav-link active":"nav-link"}  onClick={()=>shipmentFilter(null)}>All</a>
                    </li>
                    <li class="nav-item">
                      <a class={(shipmentstate.status == 1)?"nav-link active":"nav-link"}  onClick={()=>shipmentFilter(1)}>Success</a>
                    </li>
                    <li class="nav-item">
                      <a class={(shipmentstate.status == 2)?"nav-link active":"nav-link"}  onClick={()=>shipmentFilter(2)}>Pending</a>
                    </li>
                    <li class="nav-item">
                      <a class={(shipmentstate.status == 3)?"nav-link active":"nav-link"}  onClick={()=>shipmentFilter(3)}>Cancelled</a>
                    </li>
                    
                </ul>
            </section>
            <div class="card card-body border-0 shadow table-wrapper table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Initiated</th>
                            <th class="border-gray-200">Transaction ID</th>
                            <th class="border-gray-200">Mode</th>	
                            <th class="border-gray-200">Gateway ID #</th>						
                            {/* <th class="border-gray-200">Customer ID #</th> */}
                          	 <th class="border-gray-200">Customer Name</th>
                            <th class="border-gray-200">Amount</th>
                            <th class="border-gray-200">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <!-- Item --> */}
                        {state.transaction_list.map((item)=>
                        <tr>
                            <td>
                                <span class="fw-normal">
                                    <small><Moment format="MMM DD YYYY hh:mm">
                        {new Date(item.created*1000)}</Moment> <br/>
                                    {/* 5 months ago */}
                                    </small>
                                </span>
                            </td>
                            <td>
                                <a href="" data-bs-toggle="modal" data-bs-target="#modal-transaction-payment" data-bs-backdrop="false" class="fw-700 text-info" onClick={()=>modalValues(item)}>{item.transaction_id}</a>
                            </td>
                            {/* <td>
                                <span class="fw-bold">{item.payment_method} </span><br/>
                                     <small>{item.gateway_transaction_id}</small>
                            </td> */}
                            <td>
                               <small>{item.payment_method}</small><br/>
                               <small className='text-danger'>{item.payer_id!=undefined && item.payer_id!=''?item.payer_id:""}</small>
                                {/* <a href={"/shipment_detail/"+item.shipment_id} class="fw-700 text-info">{item.shipment_id}</a> */}
                            </td>
                            <td>
                                {/* <a href={"/customers_info/"+item.customer_id} class="fw-700 text-info">
                                   {item.full_name}
                                </a> */}
                                <small>{item.gateway_id!=undefined && item.gateway_id!=''?item.gateway_id:"--------------"}</small>
                            </td>  
                          
                           <td>
                           <a href={"/customers_info/"+item.customer_id} class="fw-700 text-info">
                                   {item.full_name}
                                </a>
                           </td>                      
                            <td>
                                <span class="fw-bold text-danger">
                                    {item.amount} {dimensionState.companyDetail.map((subscriber)=>( 
                  <>{subscriber.set_currency}</>
                 ))}
                                </span> <br/>
                                {/* <small>
                                    $15.00 + 10.00
                                </small> */}
                            </td>
                            <td>{item.payment_type==1?`Point credited into the wallet (${item.payment_method})`:`Point debited from the wallet (${item.payment_method})`}</td>
                            <td>
                                {(item.payment_status == 2)?
                                <span class="badge rounded-pill bg-warning">Pending</span>:(item.payment_status == 1)?
                                <span class="badge rounded-pill bg-primary">Success</span>:(item.payment_status ==3)?
                                <span class="badge rounded-pill bg-success">Cancelled</span>:""
                                    }
                                </td>
                        </tr>
                        )}
                                                    
                    </tbody>
                </table>
                <TablePagination
                            component="div"
                            rowsPerPageOptions={[500,800,1000]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                {/* <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-0">
                            <li class="page-item">
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">4</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">5</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
                </div> */}
            </div> 
                    

    </section>

    <section>
        {/* <!-- Modal Content --> */}
        <div class="modal fade" id="modal-transaction-payment" tabindex="-1" role="dialog" aria-labelledby="modal-transaction-payment" aria-hidden="true" data-bs-backdrop="false">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="h6 modal-title">Transaction #{modals.transaction_id}</h2>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <table class="table">
                            <tbody>
                              <tr>
                                <td class="fw-700 text-dark">Date</td>
                                <td><Moment format="YYYY-MM-DD hh:mm">
                        {new Date(modals.created*1000)}</Moment></td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Transaction ID</td>
                                <td>{modals.transaction_id}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">User name</td>
                                <td>{modals.full_name}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Method</td>
                                <td>{modals.payment_method}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Send to email</td>
                                <td>{modals.email}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Amount</td>
                                <td>{modals.amount}</td>
                              </tr>
                              {/* <tr>
                                <td class="fw-700 text-dark">Charge</td>
                                <td>{modals.payment_method}</td>
                              </tr>
                              <tr>
                                <td class="fw-700 text-dark">Payable</td>
                                <td>{modals.payment_method}</td>
                              </tr> */}
                              <tr>
                                <td class="fw-700 text-dark">Status</td>
                                <td>{(modals.payment_status == 2)?<span class="badge bg-warning">Pending</span>:(modals.payment_status == 1)?<span class="badge bg-success">Success</span>:(modals.payment_status == 3)?<span class="badge bg-success">Cancelled</span>:""}</td>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link text-gray-600 ms-auto" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        {/* // <!-- End of Modal Content --> */}
    </section>


    </div>
  )
}

export default Wallet_payment
